<template>
	<el-dialog :visible.sync="show" :show-close="false" :close-on-click-modal="false" :before-close="close">
		<div slot="title">
			<div class="titleLeft">
				{{i18n('236')}}
			</div>
			<div class="titleRight" @click="close">
				<img src="@/assets/close.png" alt="">
			</div>
		</div>
		<div class="list">
			<img :src="item.images" v-for="(item,index) in listData" :key="index" :class="avatarAc==item.images?'ac':''" @click="avatarChange(item)">
		</div>
		<div class="but" @click="sub">
			{{i18n('56')}}
		</div>
	</el-dialog>
</template>

<script>
	import {avatarList} from "@/api/user.js"
	export default {
		name: "txChange",
		components: {

		},
		data() {
			return {
				show:false,
				listData:[],
				seleData:false,
				avatarAc:false,
			}
		},
		methods: {
			sub(){
				this.$emit('avatarChange',this.seleData)
				this.close()
			},
			avatarChange(data){
				this.avatarAc = data.images
				this.seleData = data
			},
			async open() {
				this.avatarAc=this.$store.state.user.userInfo.avatar
				if(this.listData.length==0){
					let {data} = await avatarList()
					this.listData=data
				}
				this.show=true
			},
			close() {
				this.show=false
			}
			
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.but{
		width: 310px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		line-height: 44px;
		margin: 0 auto;
	}
	.list{
		padding: 18px 80px;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		flex-wrap: wrap;
	}
	.list img{
		width: 60px;
		height: 60px;
		display: block;
		border: 3px solid #fff;
		border-radius: 50%;
		margin-bottom: 30px;
		cursor: pointer;
	}
	.list img.ac{
		border: 3px solid rgba(86,171,123,1);
	}
	>>>.el-dialog__body {
		padding: 0 32px 50px 32px;
	}

	>>>.el-dialog {
		width: 522px;
		background: #FFFFFF;
		border-radius: 20px;
	}

	>>>.el-dialog__header {
		padding: 0 32px;
	}

	>>>.el-dialog__header div {
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	>>>.el-dialog__header .titleLeft {
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #000000;
		letter-spacing: 0;
		font-weight: 500;
	}

	>>>.el-dialog__header .titleRight {
		width: 24px;
		height: 24px;
		background: #F2F3F5;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
	}

	>>>.el-dialog__header .titleRight img {
		width: 12px;
		height: 12px;
		display: block;
	}
</style>
