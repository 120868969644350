<template>
	<el-dialog :visible.sync="show" :show-close="false" :close-on-click-modal="false" :before-close="close">
		<div slot="title">
			<div class="titleLeft">
				{{i18n('233')}}
			</div>
			<div class="titleRight" @click="close">
				<img src="@/assets/close.png" alt="">
			</div>
		</div>
		<div class="bz">
			<div class="one">
				<div :class="type==1?'top topAc':'top'">
					<div class="">
						1
					</div>
				</div>
				<div :class="type==1?'bot botAc':'bot'">
					{{i18n('234')}}
				</div>
			</div>
			<div class="xian one">
				<div class="top">
					<img src="@/assets/resetPassword/05.png" alt="">
				</div>
				<div class="bot">

				</div>
			</div>
			<div class="one">
				<div :class="type==2?'top topAc':'top'">
					<div class="">
						2
					</div>
				</div>
				<div :class="type==2?'bot botAc':'bot'">
					{{i18n('215')}}
				</div>
			</div>
		</div>
		<div class="inner">
			<div class="" style="width: 310px;" v-if="type==1">
				<inputStyle v-for="(item,index) in inputData" :key="index" :label='item.label' :type='item.type'
					:keyType='item.keyType' :verification='item.verification' :errorText='item.errorText'
					:keyValue="item.value" :codeText="codeText" @getCode="getCode" :keyName='item.keyName'
					:placeholder="item.placeholder" :value='item.value' @change="change($event,index)"></inputStyle>
			</div>
			<div class="cg" v-if="type==2">
				<img src="@/assets/resetPassword/success.png" alt="">
				<div class="">
					{{i18n('235')}}
				</div>
			</div>
		</div>
		<div class="but" @click="sub">
			{{i18n('56')}}
		</div>
	</el-dialog>
</template>

<script>
	import {
		bindPhone,
		sendCode
	} from "@/api/user.js"
	import inputStyle from '@/components/inputStyle.vue'
	export default {
		name: "changePhone",
		components: {
			inputStyle
		},
		data() {
			return {
				type: 1,
				show: false,
				inputData: [{
						label: this.i18n('16'),
						type: 'number',
						keyType: 'phone',
						verification: 1,
						placeholderLang: '04',
						errorTextLang: '03',
						labelLang: '16',
						errorText: this.i18n('03'),
						placeholder: this.i18n('04'),
						keyName: 'phone',
						value: ''
					},
					{
						label: this.i18n('10'),
						type: 'number',
						keyType: 'code',
						verification: 1,
						placeholderLang: '06',
						errorTextLang: '05',
						labelLang: '10',
						errorText: this.i18n('05'),
						placeholder: this.i18n('06'),
						keyName: 'code',
						value: '',
					}
				],
				time: 0,
				interval: false,
				codeText: this.i18n('09')
			}
		},
		methods: {
			sub(){
				if(this.type==1){
					this.bindPhone()
				}else{
					this.close()
				}
			},
			open() {
				this.show = true
			},
			close() {
				this.show = false
			},
			change(e, index) {
				this.inputData[index] = Object.assign(this.inputData[index], e)
			},
			async getCode() {
				if (this.time != 0) {
					return false
				}
				let verification = 1
				let phone = 1
				this.inputData.map(item => {
					if (item.keyName == 'phone') {
						verification = item.verification
						phone = item.value
					}
				})
				if (verification == 2) {
					await sendCode({
						phone,
						type: 0
					})
					clearInterval(this.interval);
					this.time = 60;
					this.yzmText = this.time + 's';
					this.interval = setInterval(() => {
						if (this.time > 0) {
							this.time--
							this.codeText = this.time + 's';
						} else {
							this.time = 0
							this.codeText = this.i18n('09');
							clearInterval(this.interval);
						}
					}, 1000)
				}
			},
			async bindPhone() {
				let params = {

				}
				let verification = true
				this.inputData.map(item => {
					if (item.verification == 2) {
						params[item.keyName] = item.value
					} else {
						item.verification = 3
						verification = false
					}
				})
				if (!verification) {
					return false
				}
				await bindPhone(params)
				this.$store.dispatch('user/getUserInfo')
				this.$emit("phoneSuccess",params.phone)
				this.type=2
			},
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		watch: {
			lang() {
				this.inputData.phone.map(item => {
					item.errorText = this.i18n(item.errorTextLang)
					item.placeholder = this.i18n(item.placeholderLang)
					item.label = this.i18n(item.labelLang)
				})
				this.inputData.paw.map(item => {
					item.errorText = this.i18n(item.errorTextLang)
					item.placeholder = this.i18n(item.placeholderLang)
					item.label = this.i18n(item.labelLang)
				})
			}
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.but{
		width: 310px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		margin: 0 auto;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		line-height: 44px;
	}
	.cg img{
		width: 140px;
		height: 140px;
		display: block;
		margin: 0 auto;
	}
	.cg  div{
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1D2129;
		text-align: center;
		font-weight: 500;
	}
	.inner {
		height: 216px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.bz {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 8px;
	}

	.bz .one {
		width: 90px;
	}

	.bz .one .top {
		width: 44px;
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		margin: 0 auto;
	}

	.bz .one .topAc {
		background: rgba(86, 171, 123, 0.24);
	}

	.bz .one .top div {
		width: 32px;
		height: 32px;
		background: #4E5969;
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		line-height: 32px;
		border-radius: 50%;
	}

	.bz .one .topAc div {
		background: #56AB7B;
	}

	.bz .one .bot {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		text-align: center;
		font-weight: 400;
		padding-top: 4px;
	}

	.bz .one .botAc {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #56AB7B;
		text-align: center;
		font-weight: 400;
	}

	.bz .xian {
		width: 100px;
	}

	.bz .xian .top {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.bz .xian .top img {
		width: 100px;
		height: 11px;
		display: block;
		margin-top: -20px;
	}

	>>>.el-dialog__body {
		padding: 0 32px 50px 32px;
	}

	>>>.el-dialog {
		width: 522px;
		background: #FFFFFF;
		border-radius: 20px;
	}

	>>>.el-dialog__header {
		padding: 0 32px;
	}

	>>>.el-dialog__header div {
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	>>>.el-dialog__header .titleLeft {
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #000000;
		letter-spacing: 0;
		font-weight: 500;
	}

	>>>.el-dialog__header .titleRight {
		width: 24px;
		height: 24px;
		background: #F2F3F5;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
	}

	>>>.el-dialog__header .titleRight img {
		width: 12px;
		height: 12px;
		display: block;
	}
</style>
