<template>
	<div class="info">
		<div class="title">
			{{i18n('21')}}
		</div>
		<div class="inn">
			<div class="tx item">
				<div class="label">
					{{i18n('237')}}：
				</div>
				<img :src="userInfo.avatar" alt="">
				<div class="text" @click="txChangeOpen">
					{{i18n('238')}}
				</div>
			</div>
			<div class="list">
				<div class="item">
					<div class="label">
						{{i18n('239')}}：
					</div>
					<input type="text" v-model="userInfo.nick_name" class="input">
				</div>
				<div class="item">
					<div class="label">
						{{i18n('240')}}：
					</div>
					<!-- <input type="text" v-model="userInfo.birthday" class="input"> -->
					<el-date-picker class="input" v-model="userInfo.birthday" value-format="yyyy-MM-dd" format="yyyy 年 MM 月 dd 日" type="date" :placeholder="i18n('242')">
					</el-date-picker>
				</div>
				<div class="item">
					<div class="label">
						{{i18n('241')}}：
					</div>
					<input type="text" v-model="userInfo.email" class="input">
					<!-- <div class="text">
						更换
					</div> -->
				</div>
				<div class="item">
					<div class="label">
						{{i18n('243')}}：
					</div>
					<!-- <input type="text" v-model="userInfo.sex" class="input"> -->
					<el-select v-model="userInfo.sex" :placeholder="i18n('49')" class="input">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="item">
					<div class="label">
						{{i18n('244')}}：
					</div>
					<input type="text" v-model="userInfo.phone" disabled class="input">
					<div class="text" @click="changePhoneOpen">
						{{i18n('238')}}
					</div>
				</div>
			</div>
			<div class="but" @click="sumb">
				<div class="bc" @click="$router.push('/resetPassword')">
					{{i18n('18')}}
				</div>
				<div class="bc" @click="sumb">
					{{i18n('245')}}
				</div>
			</div>
		</div>
		<txChange ref="txChange" @avatarChange="avatarChange"></txChange>
		<changePhone ref="changePhone" @phoneSuccess="phoneSuccess"></changePhone>
	</div>
</template>

<script>
	import {
		update
	} from "@/api/user.js"
	
	import txChange from "./components/txChange.vue"
	import changePhone from "./components/changePhone.vue"
	export default {
		name: "info",
		components: {
			txChange,
			changePhone
		},
		data() {
			return {
				userInfo: {

				},
				options: [{
						label: this.i18n('246'),
						value: 0
					}, {
						label: this.i18n('247'),
						value: 1
					},
					{
						label: this.i18n('248'),
						value: 2
					},
					{
						label: this.i18n('249'),
						value: 3
					},
				]
			}
		},
		methods: {
			phoneSuccess(phone){
				this.userInfo.phone=phone.substring(2)
			},
			changePhoneOpen(){
				this.$refs.changePhone.open()
			},
			txChangeOpen(){
				this.$refs.txChange.open()
			},
			avatarChange(data){
				this.userInfo.avatar = data.images
				this.userInfo.avatar_id = data.id
			},
			async sumb() {
				let params = {
					avatar_id:0
				}
				params.nick_name = this.userInfo.nick_name
				params.birthday = this.userInfo.birthday
				params.sex = Number(this.userInfo.sex)
				if (this.userInfo.avatar_id) {
					params.avatar_id = Number(this.params.avatar_id)
				}
				await update(params)
				this.$store.dispatch('user/getUserInfo')
				this.$message.success(this.i18n('223'))
			},
			getDate(type) {
				const date = new Date();
				let year = date.getFullYear();
				let month = date.getMonth() + 1;
				let day = date.getDate();
			
				if (type === 'start') {
					year = year - 60;
				} else if (type === 'end') {
					year = year + 2;
				}
				month = month > 9 ? month : '0' + month;
				day = day > 9 ? day : '0' + day;
				return `${year}-${month}-${day}`;
			}
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		created() {
			this.$store.commit('user/setClassifyShow', false)
			this.userInfo = JSON.parse(JSON.stringify(this.$store.state.user.userInfo))
			this.userInfo.phone = this.userInfo.phone.substring(2)
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.but{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.but div:nth-child(1){
		margin-right: 20px;
	}
	.bc {
		width: 310px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		text-align: center;
		line-height: 44px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		margin-top: 20px;
		cursor: pointer;
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 140px;
		flex-wrap: wrap;
	}

	.item {
		display: flex;
		align-items: center;
		margin-bottom: 24px;
	}

	.item .label {
		width: 82px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
	}

	.item .input {
		width: 215px;
		height: 32px;
		border: 1px solid rgba(170, 181, 195, 1);
		border-radius: 16px;
		padding: 0 16px;
		box-sizing: border-box;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		line-height: 22px;
		font-weight: 400;
	}

	.tx img {
		width: 44px;
		height: 44px;
		border-radius: 50%;
		overflow: hidden;
	}

	.item .text {
		padding-left: 12px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #56AB7B;
		font-weight: 400;
		cursor: pointer;
	}

	.inn {
		width: 1032px;
		height: 382px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 29px 24px;
		box-sizing: border-box;
	}

	.title {
		height: 69px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1D2129;
		line-height: 69px;
		font-weight: 500;
	}

	.info {
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow-y: scroll;
	}

	>>>.el-input__icon {
		line-height: 30px;
	}

	>>>.el-input__inner {
		border: none;
		height: 30px;
	}

	>>>.el-input__inner:focus {
		border: none !important;
	}

	>>>.el-select .el-input.is-focus .el-input__inner {
		border: none !important;
	}
</style>
